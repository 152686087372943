import React from "react"
import { graphql } from 'gatsby'
import Layout from "../components/layout"
import styled from 'styled-components'
import YoutubeVideo from "../components/youtubeVideo"
import Seo from "../components/seo"

const FlexContent = styled.div`
 	  display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items:flex-start;
    margin-top:1.6rem;
    flex-basis:50%;
    @media (max-width: ${props => props.theme.breakpoints[0]}) {
      flex-direction:column;
      gap:1rem;
     
      } 
`



const Videos = ({data}) => {

  return (
  <Layout>
    <Seo meta={data.seoMetaTags} />
    <h1 className="breadcrumb">Videos</h1>
    <div className="container">
      <FlexContent>
      { data.videosList.videos.map(( item,i) => ( 
          <YoutubeVideo videoSrcURL={`https://www.youtube.com/embed/`+item.video.providerUid} videoTitle={item.titre} key={i} />    
      ))
        }
      </FlexContent>
    </div>
  </Layout>
  )
}

export default Videos

export const query = graphql`
  query VideosQuery {
    videosList:datoCmsPageVideo {
      seoMetaTags  {
        ...GatsbyDatoCmsSeoMetaTags
      }
      videos {
        titre
        video {
          providerUid
          thumbnailUrl
          url
        }
      
    }
  }
}
`
/*
page:datoCmsVideopage {
  seoMetaTags  {
    ...GatsbyDatoCmsSeoMetaTags
  }
}*/